import React, { FC, useState, useEffect } from 'react';
import classnames from 'classnames';

// Components
import SectionHeadings from '../SectionHeadings/SectionHeadings';
import SkeletonSectionHeadings from '../SectionHeadings/SkeletonSectionHeadings';
import TalksGridItems from '../listGridItems/TalksGridItems/TalksGridItems';
import LoadMore from '../LoadMore/LoadMore';
import SkeletonTalksGridItems from '../listGridItems/TalksGridItems/Skeleton';
// Libs
import getFetch from '../../libs/getFetch/getFetch';
import removeDuplications from '../../libs/removeDuplications/removeDuplications';

export interface TalksProps {
  latestTalksGrid: Communication[];
  foundTalks: number;
  listLayout?: 'horizontal-list' | 'vertical-list'; // we don't want to pass this for talks grid homepage
  cardLayout?: 'horizontal-card' | 'vertical-card';
  footLinkTitle?: string;
  gridItemName?: string;
  companyLogo: boolean;
  imgHoverOverlay?: boolean;
  pageType?: string; // needed to pass into the image comp to try and format different resolutions.
  rowColSm?: number;
  rowColMd?: number;
  rowColLg?: number;
  rowColXl?: number;
  utmParams?: string;
  talksFilterParams?: TalksFilterParamsArray;
  baseUrl: string;
  setLiveLabel?: boolean;
  priorityStatus?: boolean;
  headingBlockName?: string
  sectionHeading: true | false;
  sectionHeadingTitle?: string;
  sectionHeadingDescription?: string;
  sectionHeadingLink?: string;
  sectionHeadingLinkText?: string;
  sectionHeadingBddTitle?: string;
  sectionHeadingBddDesc?: string;
  sectionHeadingBddLink?: string;
  skeletonHeadingDescription?: boolean;
  isFirstBlock?: boolean;
  linkNewTab?: boolean;
  certificateLink?: boolean;
}

export interface TalksFilterParamsArray {
	size?: number;
	bq?: string;
	rank?: string;
	rankClosest?: string;
  }

export interface Communication {
  id: number;
  communication?: {
    id: number;
  };
  communicationId?: number;
  summary?: string;
  channel: Channel[];
  title: string;
  description: string;
  keywords: string;
  presenter: string;
  status: "live" | "upcoming" | "recorded" | "processing";
  scheduled: Date;
  entryTime: Date;
  closeTime: Date;
  created: Date;
  lastUpdated: Date;
  duration: number;
  start: Date;
  timeZone: string;
  format: string;
  publishStatus: string;
  visibility: string;
  links: Link[];
  url: string;
  channelWebcastUrl: string;
  rating: number;
  totalViewings: number;
  uniqueViewers: number;
  registrations: number;
  ratingCount: number;
  categories?: Categories[];
}

export interface Categories {
  label: string;
  term: string;
}
export interface Channel {
  id: number;
  link: Link[];
}

export interface Link {
  href: string;
  rel: string;
  type: string;
  title: string;
}

const TalksGrid: FC<TalksProps> = ({
  latestTalksGrid,
  foundTalks,
  listLayout,
  cardLayout,
  footLinkTitle,
  gridItemName,
  companyLogo,
  imgHoverOverlay,
  pageType,
  rowColSm,
  rowColMd,
  rowColLg,
  rowColXl,
  utmParams = '',
  talksFilterParams,
  baseUrl,
  setLiveLabel,
  priorityStatus,
  headingBlockName,
  sectionHeading,
  sectionHeadingTitle,
  sectionHeadingDescription,
  sectionHeadingLink,
  sectionHeadingLinkText,
  sectionHeadingBddTitle,
  sectionHeadingBddDesc,
  sectionHeadingBddLink,
  skeletonHeadingDescription,
  linkNewTab,
  isFirstBlock,
  certificateLink,
}) => {
  let defaultSize;

  if (
    talksFilterParams?.['size'] > 0 ||
    talksFilterParams?.['size'] !== undefined
  ) {
    defaultSize = Number(talksFilterParams['size']);
  } else {
    defaultSize = 8;
  }

  const [talksData, setTalksData] = useState(latestTalksGrid);
  const [visible, setVisible] = useState<number>(defaultSize);
  const [showLoadMore, setShowLoadMore] = useState<boolean>(false);
  const [isLoadingLatestTalks, setIsLoadingLatestTalks] =
    useState<boolean>(true);

  useEffect(() => {
    const getLatestTalks = async () => {
      setIsLoadingLatestTalks(talksData && false);
    };
    getLatestTalks();
  });

   const url = '/api/webcasts';
  const loadMorePage = async () => {

    setShowLoadMore(true);

  let bqParam;

  if(talksFilterParams['bq']){
    bqParam = talksFilterParams['bq']
  }else if ( talksFilterParams['duration'] === undefined || talksFilterParams['duration'] === 'undefined' || talksFilterParams['duration'] === '' ) {
    bqParam = `(and type:'${talksFilterParams['contentType']}')`
  } else {
    bqParam = `(and type:'${talksFilterParams['contentType']}' duration:${talksFilterParams['duration']})`
  }

    // Fetch new set of talks. We call latestTalks endpoint via backend to avoid CORS issue
    const resJson = await getFetch(baseUrl + url, {
      start: visible,
      size: talksFilterParams['size'],
      rank: talksFilterParams['rank'],
      q: talksFilterParams['q'],
      returnFields: talksFilterParams['returnFields'],
      rankClosest: talksFilterParams['rankClosest'],
      bq: bqParam,
      paidSearch: talksFilterParams['paidSearch']
    });

    // Push new set of data.
    setTalksData((oldTalksData) => removeDuplications(resJson['communications'], oldTalksData));

    // After no. of talks loads, updating state:visible => total numbers of talks that are already loaded
    // Note: visiblity of load more section depends  on this state,
    setVisible((prevValue) => prevValue + defaultSize);


    // Turn off loading spinner
    setShowLoadMore(false);

  };

  // Hide load more if visible is more less or equals to found talks
  let displayLoadMore;
  if (foundTalks > visible) {
    displayLoadMore = true;
  } else {
    displayLoadMore = false;
  }

  return  <div className={classnames('latest-talk-block', 'grid-box')} data-bdd="talks-grid">

   {(() => {

    if ( sectionHeading == true ) {

     return (

        <SectionHeadings
          title={sectionHeadingTitle}
          description={sectionHeadingDescription}
          link={sectionHeadingLink}
          linkTitle={sectionHeadingLinkText}
          headingBlockName={headingBlockName}
          dataBddTitle={sectionHeadingBddTitle}
          dataBddDesc={sectionHeadingBddDesc}
          dataBddLink={sectionHeadingBddLink}
          // set a class for the first grid to remove margin top
          {... (isFirstBlock == true &&  {classes: "no-margin-top" } ) }
         />
       );
    } else {
      <></>
    }

   })()}

    {(() => {
      return (
          <>

          <TalksGridItems
            gridData={talksData}
            footLinkTitle={footLinkTitle}
            gridItemName={gridItemName}
            rowColSm={rowColSm}
            rowColLg={rowColLg}
            rowColXl={rowColXl}
            listStyle={'card'}
            listLayout={listLayout}
            cardLayout={cardLayout}
            companyLogo={companyLogo}
            imgHoverOverlay={imgHoverOverlay}
            utmParams={utmParams}
            setLiveLabel={setLiveLabel}
            priorityStatus={priorityStatus}
            envUrl={baseUrl}
            certificateLink={certificateLink}
          />

          <LoadMore
           onClick={loadMorePage}
           loader={showLoadMore}
           showLoadMore={displayLoadMore}
           bddLoadmoreBlock={'latest-load-more-btn'}
           gridItemName={gridItemName}
          />
          </>
          );
    })()}

  </div>

};

export default TalksGrid;
